export  function Reducer(state = {
translate:'en',
sd:"2",
ac:"le",
ref:true
}, action) {
    switch (action.type) {

        case 'TRANS':{
            let a
            if(action.tr==="ar")
               a="1"
               else if(action.tr==="en")
               a="2"
               else a="3"
               localStorage.setItem("lang",action.tr)
            return({
                ...state,
                translate:action.tr,
                sd:a
            })
        }
        case "ac":{
            let temp = ""
            let tem = state.ac
            if(tem === "le")
                temp="right"
                else
                temp="le"
            return({
                ...state,
                ac:temp
            })
        }
        case "ref":{
            return({
                ...state,
                ref:!state.ref
            })
        }
        default:
    }
    return state;
}