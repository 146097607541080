import React from 'react'

function Marker() {
  return (<svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_4644" data-name="Rectangle 4644" width="20" height="20" fill="none"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_280" data-name="Mask Group 280" clip-path="url(#clip-path)">
    <g id="Mask_Group_208" data-name="Mask Group 208" clip-path="url(#clip-path)">
      <path id="location-pin" d="M8.75,0a7.536,7.536,0,0,0-7.5,7.555c0,5.919,6.8,12.031,7.084,12.288a.627.627,0,0,0,.832,0c.289-.257,7.084-6.369,7.084-12.288A7.536,7.536,0,0,0,8.75,0Zm0,11.667A4.167,4.167,0,1,1,12.917,7.5,4.167,4.167,0,0,1,8.75,11.667Z" transform="translate(1.25)" fill="#404040"/>
    </g>
  </g>
</svg>

  )
}

export default Marker