import React from 'react'

function Input(props) {
  return (
    <>
      {props.icon&&  <div className='input-icon'>
           <img src={props.icon}/>
        </div>}
      
      {props.label&&  <div className='input-label'>{props.label}</div>}
        <div className='input-element' style={{marginTop:props.label?"0px":"0px"}}>
            <input id={props.id} type={props.type||"text"} style={{paddingLeft:props.pad?"53px":"20px"}} value={props.value} onChange={(e)=>props.onChange(e.target.value)}/>
        </div>
        
    </>
  )
}

export default Input