import React, {useEffect, useState} from "react";
import {uploadFile} from "./uploadService";
import { Modal, ModalBody} from "reactstrap";
import {BACKEND_FIles_URL} from "../constants/endpointConfig";
import {x} from "../assets/trans"
import { useDispatch,useSelector } from 'react-redux';
import fileIcon from "./download.png" 
export default function UploadImageDialog({
  open,
  handleClose,
  handleFinishUpLoadSuccessfully,
  handleAdd,
  dialogTitle,
  folder,
  pathupload = "undefined",
    fileName="file_name",
    imagePath,
    setImagePath,
    rese,m,
    loading
}) {
  const tr = useSelector((state) => state.trans.translate)
  const dispatch = useDispatch()
  const [currentFile, setCurrentFile] = useState(imagePath.length > 0 ? imagePath[0]:undefined);
  const [progress, setProgress] = useState(0);

  

  const selectFile = (event) => {
    //console.table('hi from selected files');
    //console.table(event.target); 
 
    upload(event.target.files)
  };
  const addAndClose = (fileInfos) => {
    setImagePath([]);
    rese()
    
    handleAdd(imagePath);

  
    
    setProgress(0)
   
  };

useEffect(()=>{
if(imagePath.length===0 && m=== true)
setCurrentFile(undefined)
},[imagePath,m])
  const upload = (a) => {
    let currentFile = a[0];
    
    setProgress(0);
    setCurrentFile(currentFile);
    if (pathupload !== undefined) {
      dispatch({type:'loading'})
      uploadFile(currentFile.name.split('.')[0], folder , currentFile, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
        .then((response) => {
          console.table(response.data.data);
       
       
          setImagePath([...imagePath,response.data.data]);

          handleFinishUpLoadSuccessfully([...imagePath,response.data.data]);
        
       
          dispatch({type:'load'})
          return response.data.data;
        })
        .then((files) => {
           // console.table(files);
         
        })
        .catch((e) => {
          setProgress(0);
   
          console.error(e);
          setCurrentFile(undefined);
        });
    }
  };
  return (
    <Modal
      centered
      isOpen={open}
      //onHide={handleClose}
      //aria-labelledby="form-dialog-title"
      //style = {{ zIndex: '10000' }}
    >
          <div className="svg_abs" style={{top:"20%"}} onClick={() => {
            let a= document.getElementsByClassName("svg_abs")[0]
            a.style.transform="scale(0.85)"
            setTimeout(() => {
              a.style.transform="scale(1.06)"
              a.style.transform="scale(1.0)"
         
            }, 200);
            setTimeout(() => {
              handleClose()
            }, 250);
          }}>
     <svg style={{cursor:'pointer'}}  xmlns="http://www.w3.org/2000/svg" width="63.119" height="63.119" viewBox="0 0 63.119 63.119">
  <path id="Path_18877" data-name="Path 18877" d="M39.425,17.109h-11.9V5.207a5.207,5.207,0,1,0-10.414,0v11.9H5.207a5.207,5.207,0,1,0,0,10.414h11.9v11.9a5.207,5.207,0,1,0,10.414,0v-11.9h11.9a5.207,5.207,0,1,0,0-10.414Zm0,8.926H26.779a.744.744,0,0,0-.744.744V39.425a3.719,3.719,0,1,1-7.439,0V26.779a.744.744,0,0,0-.744-.744H5.207a3.719,3.719,0,1,1,0-7.439H17.853a.744.744,0,0,0,.744-.744V5.207a3.719,3.719,0,1,1,7.439,0V17.853a.744.744,0,0,0,.744.744H39.425a3.719,3.719,0,1,1,0,7.439Z" transform="translate(31.559) rotate(45)" fill="#fff"/>
</svg>
     </div>
      <ModalBody id="form-dialog-title">
 

      <div onClick={(e) => {
                let ripple = document.createElement("span");
  
                // Add ripple class to span
                ripple.classList.add("Rspan");
                ripple.classList.add("ripple");
        let divM = document.getElementsByClassName("divModal")[0]
        divM.appendChild(ripple);
        // Position the span element
        ripple.style.left = `${47}px`;
        ripple.style.top = `${33}px`;
        ripple.style.display = "block"
        setTimeout(() => {
          ripple.remove();
      }, 700);
      }} className="divModal" tabIndex={"1"} role="button" for="browse">
     { <label id="labek" tabIndex={"1"} role="button" for="browse"></label>}
      <input hidden id="browse" accept=".jpeg,.png,.jpg,.pdf,.doc" type="file" onChange={selectFile} style={{color:'#e9ecef'}}/>
   <div className="divMod">
   <div className="firsec">
      <svg xmlns="http://www.w3.org/2000/svg" width="32.216" height="45.102" viewBox="0 0 32.216 45.102">
  <g id="_x32_9_Upload_cv" transform="translate(0)">
    <g id="Group_7587" data-name="Group 7587" transform="translate(0 0)">
      <path id="Path_15413" data-name="Path 15413" d="M94.372,216.049a.287.287,0,0,1-.287-.287v-.757a.287.287,0,0,1,.574,0v.76A.287.287,0,0,1,94.372,216.049Z" transform="translate(-92.248 -195.805)" fill="#5a5a5a"/>
      <path id="Path_15414" data-name="Path 15414" d="M94.372,242.563a.287.287,0,0,1-.287-.287v-2.259a.287.287,0,1,1,.574,0v2.259A.287.287,0,0,1,94.372,242.563Z" transform="translate(-92.248 -218.614)" fill="#5a5a5a"/>
      <path id="Path_15415" data-name="Path 15415" d="M74.841,30.677l2.986-3.207a.287.287,0,1,0-.42-.391l-2.986,3.207a.287.287,0,1,0,.42.391Z" transform="translate(-74.247 -24.622)" fill="#5a5a5a"/>
      <path id="Path_15416" data-name="Path 15416" d="M120.519,8.76l.546-.585a.287.287,0,1,0-.42-.391l-.546.585a.287.287,0,0,0,.42.391Z" transform="translate(-115.899 -7.027)" fill="#5a5a5a"/>
      <path id="Path_15417" data-name="Path 15417" d="M387.793,276.11a.287.287,0,0,0,.287-.287v-.8a.287.287,0,0,0-.574,0v.8A.287.287,0,0,0,387.793,276.11Z" transform="translate(-359.807 -250.531)" fill="#5a5a5a"/>
      <path id="Path_15418" data-name="Path 15418" d="M387.793,306.121a.287.287,0,0,0,.287-.287v-4.382a.287.287,0,1,0-.574,0v4.382A.287.287,0,0,0,387.793,306.121Z" transform="translate(-359.807 -274.633)" fill="#5a5a5a"/>
      <path id="Path_15419" data-name="Path 15419" d="M117.769,114.209h5.992a.287.287,0,1,0,0-.574h-5.992a.287.287,0,0,0,0,.574Z" transform="translate(-113.583 -103.632)" fill="#5a5a5a"/>
      <path id="Path_15420" data-name="Path 15420" d="M117.769,152.693H126.8a.287.287,0,1,0,0-.574h-9.033a.287.287,0,1,0,0,.574Z" transform="translate(-113.583 -138.724)" fill="#5a5a5a"/>
      <path id="Path_15421" data-name="Path 15421" d="M117.769,191.177h18.958a.287.287,0,1,0,0-.574H117.769a.287.287,0,0,0,0,.574Z" transform="translate(-113.583 -173.816)" fill="#5a5a5a"/>
      <path id="Path_15422" data-name="Path 15422" d="M117.769,229.659h18.958a.287.287,0,1,0,0-.574H117.769a.287.287,0,0,0,0,.574Z" transform="translate(-113.583 -208.906)" fill="#5a5a5a"/>
      <path id="Path_15423" data-name="Path 15423" d="M117.769,268.143h18.958a.287.287,0,0,0,0-.574H117.769a.287.287,0,0,0,0,.574Z" transform="translate(-113.583 -243.998)" fill="#5a5a5a"/>
      <path id="Path_15424" data-name="Path 15424" d="M117.769,306.627h18.958a.287.287,0,1,0,0-.574H117.769a.287.287,0,1,0,0,.574Z" transform="translate(-113.583 -279.09)" fill="#5a5a5a"/>
      <path id="Path_15425" data-name="Path 15425" d="M117.769,345.108h18.958a.287.287,0,1,0,0-.574H117.769a.287.287,0,0,0,0,.574Z" transform="translate(-113.583 -314.18)" fill="#5a5a5a"/>
      <path id="Path_15426" data-name="Path 15426" d="M99.683,32.322V2.895a2.752,2.752,0,0,0-2.75-2.75h-15.6a.311.311,0,0,0-.209.092l-7.8,8.369a.32.32,0,0,0-.078.2V36.143A2.753,2.753,0,0,0,76,38.893H92.5a6.48,6.48,0,1,0,7.18-6.572Zm-22.249,1.57a.287.287,0,1,0,0,.574h16.7a6.436,6.436,0,0,0-1.618,3.854H76a2.178,2.178,0,0,1-2.176-2.176V9.088h5.048a2.752,2.752,0,0,0,2.75-2.75V.719H96.933a2.178,2.178,0,0,1,2.176,2.176v29.39l-.129,0a6.453,6.453,0,0,0-4.267,1.609Zm23.631,3.42a.287.287,0,0,1-.406,0l-1.391-1.391V42.3a.287.287,0,0,1-.574,0V35.921L97.3,37.312a.287.287,0,1,1-.406-.406l1.882-1.88a.287.287,0,0,1,.406,0l1.88,1.88a.287.287,0,0,1,0,.406Z" transform="translate(-73.247 -0.145)" fill="#5a5a5a"/>
      <path id="Path_15427" data-name="Path 15427" d="M219.52,33.557a5.512,5.512,0,1,0,5.511,5.511A5.518,5.518,0,0,0,219.52,33.557Zm2.445,6.712a1.158,1.158,0,0,0-.978.335l-1.467,1.467L218.051,40.6a1.184,1.184,0,0,0-1-.328,3.265,3.265,0,0,0-1.965.969,4.939,4.939,0,1,1,8.867,0A3.263,3.263,0,0,0,221.965,40.269Z" transform="translate(-201.6 -30.612)" fill="#5a5a5a"/>
      <path id="Path_15428" data-name="Path 15428" d="M255.814,51.686c0,1.205-.953,3.064-2.217,3.064s-2.22-1.859-2.22-3.064a2.219,2.219,0,0,1,4.437,0Z" transform="translate(-235.677 -45.122)" fill="#5a5a5a"/>
    </g>
  </g>
</svg>
        <div className="uplo" style={{marginLeft:"10px"}}>{x[tr].uploadc}</div>
 

      </div>
     <label role="button" for="browse" style={{cursor:"pointer"}}>
     <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
  <path id="Path_18878" data-name="Path 18878" d="M18.55,8.05h-5.6V2.45a2.45,2.45,0,1,0-4.9,0v5.6H2.45a2.45,2.45,0,1,0,0,4.9h5.6v5.6a2.45,2.45,0,0,0,4.9,0v-5.6h5.6a2.45,2.45,0,0,0,0-4.9Zm0,4.2H12.6a.35.35,0,0,0-.35.35v5.95a1.75,1.75,0,1,1-3.5,0V12.6a.35.35,0,0,0-.35-.35H2.45a1.75,1.75,0,1,1,0-3.5H8.4a.35.35,0,0,0,.35-.35V2.45a1.75,1.75,0,1,1,3.5,0V8.4a.35.35,0,0,0,.35.35h5.95a1.75,1.75,0,1,1,0,3.5Z" fill="#5a5a5a"/>
</svg>
     </label>
      <svg  xmlns="http://www.w3.org/2000/svg" width="139.308" height="23.599" viewBox="0 0 139.308 23.599">
  <g id="Group_7598" data-name="Group 7598" transform="translate(-225 -396.201)">
    <g id="Group_7594" data-name="Group 7594" transform="translate(225 396.201)">
      <g id="pdf-2" transform="translate(0)" opacity="0.504">
        <g id="Group_7589" data-name="Group 7589" transform="translate(0)">
          <g id="Group_7588" data-name="Group 7588">
            <path id="Path_15430" data-name="Path 15430" d="M60.49,0H48.69a2.145,2.145,0,0,0-2.145,2.145V21.453A2.145,2.145,0,0,0,48.69,23.6H63.708a2.145,2.145,0,0,0,2.145-2.145V5.9ZM64.78,21.453a1.073,1.073,0,0,1-1.073,1.073H48.69a1.073,1.073,0,0,1-1.073-1.073V2.145A1.073,1.073,0,0,1,48.69,1.073h9.654V6.436a1.073,1.073,0,0,0,1.073,1.073H64.78V21.453ZM59.417,6.436V1.073h.536L64.78,6.436H59.417Z" transform="translate(-46.545)" fill="#5a5a5a"/>
          </g>
        </g>
      </g>
      <path id="Path_15431" data-name="Path 15431" d="M.477,0V-5.727H2a4.658,4.658,0,0,1,1.113.082,1.262,1.262,0,0,1,.689.566,2.076,2.076,0,0,1,.271,1.117A2.111,2.111,0,0,1,3.84-2.92a1.361,1.361,0,0,1-.586.59,3.042,3.042,0,0,1-1.211.17H1.422V0Zm.945-4.758v1.625h.523a2.281,2.281,0,0,0,.717-.074.639.639,0,0,0,.311-.268.877.877,0,0,0,.121-.475.874.874,0,0,0-.125-.48.617.617,0,0,0-.309-.262,2.825,2.825,0,0,0-.777-.066ZM4.863,0V-5.727h.922L7.707-1.9V-5.727H8.59V0H7.637L5.746-3.734V0Zm6.91-2.105V-3.07h2.043V-.789a2.5,2.5,0,0,1-.875.625A2.689,2.689,0,0,1,11.8.1a2.217,2.217,0,0,1-1.227-.342,2.316,2.316,0,0,1-.842-1.023,3.838,3.838,0,0,1-.311-1.6A3.791,3.791,0,0,1,9.74-4.488a2.364,2.364,0,0,1,.816-1,2.127,2.127,0,0,1,1.2-.332A2.015,2.015,0,0,1,13.1-5.4a2.035,2.035,0,0,1,.65,1.256l-.937.215A1.215,1.215,0,0,0,12.43-4.6a1.016,1.016,0,0,0-.672-.234,1.194,1.194,0,0,0-.98.471A2.282,2.282,0,0,0,10.4-2.93a2.586,2.586,0,0,0,.406,1.574,1.114,1.114,0,0,0,.941.465,1.434,1.434,0,0,0,.576-.131,1.8,1.8,0,0,0,.533-.357v-.727Z" transform="translate(2.523 20)" fill="#5a5a5a"/>
    </g>
    <g id="Group_7595" data-name="Group 7595" transform="translate(265 396.201)">
      <g id="pdf-2-2" data-name="pdf-2" opacity="0.504">
        <g id="Group_7589-2" data-name="Group 7589" transform="translate(0)">
          <g id="Group_7588-2" data-name="Group 7588">
            <path id="Path_15430-2" data-name="Path 15430" d="M60.49,0H48.69a2.145,2.145,0,0,0-2.145,2.145V21.453A2.145,2.145,0,0,0,48.69,23.6H63.708a2.145,2.145,0,0,0,2.145-2.145V5.9ZM64.78,21.453a1.073,1.073,0,0,1-1.073,1.073H48.69a1.073,1.073,0,0,1-1.073-1.073V2.145A1.073,1.073,0,0,1,48.69,1.073h9.654V6.436a1.073,1.073,0,0,0,1.073,1.073H64.78V21.453ZM59.417,6.436V1.073h.536L64.78,6.436H59.417Z" transform="translate(-46.545)" fill="#5a5a5a"/>
          </g>
        </g>
      </g>
      <path id="Path_15432" data-name="Path 15432" d="M.477,0V-5.727H2a4.658,4.658,0,0,1,1.113.082,1.262,1.262,0,0,1,.689.566,2.076,2.076,0,0,1,.271,1.117A2.111,2.111,0,0,1,3.84-2.92a1.361,1.361,0,0,1-.586.59,3.042,3.042,0,0,1-1.211.17H1.422V0Zm.945-4.758v1.625h.523a2.281,2.281,0,0,0,.717-.074.639.639,0,0,0,.311-.268.877.877,0,0,0,.121-.475.874.874,0,0,0-.125-.48.617.617,0,0,0-.309-.262,2.825,2.825,0,0,0-.777-.066Zm3.426-.969h1.73a2.576,2.576,0,0,1,.99.146,1.659,1.659,0,0,1,.615.494,2.54,2.54,0,0,1,.441.9A5.05,5.05,0,0,1,8.785-2.8a4.43,4.43,0,0,1-.168,1.291,2.589,2.589,0,0,1-.426.869,1.65,1.65,0,0,1-.623.482A2.331,2.331,0,0,1,6.629,0H4.848Zm.945.969V-.965H6.5a1.85,1.85,0,0,0,.662-.086.8.8,0,0,0,.336-.26,1.489,1.489,0,0,0,.217-.549A4.582,4.582,0,0,0,7.8-2.848a4.51,4.51,0,0,0-.088-1,1.33,1.33,0,0,0-.273-.578.87.87,0,0,0-.451-.277,3.746,3.746,0,0,0-.77-.051ZM9.594,0V-5.727h3.219v.969H10.539V-3.4H12.5v.969H10.539V0Z" transform="translate(2.523 20)" fill="#5a5a5a"/>
    </g>
    <g id="Group_7596" data-name="Group 7596" transform="translate(305 396.201)">
      <g id="pdf-2-3" data-name="pdf-2" opacity="0.504">
        <g id="Group_7589-3" data-name="Group 7589" transform="translate(0)">
          <g id="Group_7588-3" data-name="Group 7588">
            <path id="Path_15430-3" data-name="Path 15430" d="M60.49,0H48.69a2.145,2.145,0,0,0-2.145,2.145V21.453A2.145,2.145,0,0,0,48.69,23.6H63.708a2.145,2.145,0,0,0,2.145-2.145V5.9ZM64.78,21.453a1.073,1.073,0,0,1-1.073,1.073H48.69a1.073,1.073,0,0,1-1.073-1.073V2.145A1.073,1.073,0,0,1,48.69,1.073h9.654V6.436a1.073,1.073,0,0,0,1.073,1.073H64.78V21.453ZM59.417,6.436V1.073h.536L64.78,6.436H59.417Z" transform="translate(-46.545)" fill="#5a5a5a"/>
          </g>
        </g>
      </g>
      <path id="Path_15433" data-name="Path 15433" d="M2.172-5.727h.949V-2.1A3.66,3.66,0,0,1,2.945-.793a1.4,1.4,0,0,1-.525.66A1.531,1.531,0,0,1,1.559.1,1.3,1.3,0,0,1,.5-.367a2.186,2.186,0,0,1-.387-1.4l.895-.125a1.739,1.739,0,0,0,.129.715.489.489,0,0,0,.457.289A.49.49,0,0,0,2.031-1.1a1.939,1.939,0,0,0,.141-.928ZM4.125,0V-5.727H5.648a4.658,4.658,0,0,1,1.113.082,1.262,1.262,0,0,1,.689.566,2.076,2.076,0,0,1,.271,1.117A2.111,2.111,0,0,1,7.488-2.92a1.361,1.361,0,0,1-.586.59,3.042,3.042,0,0,1-1.211.17H5.07V0ZM5.07-4.758v1.625h.523a2.281,2.281,0,0,0,.717-.074.639.639,0,0,0,.311-.268.877.877,0,0,0,.121-.475.874.874,0,0,0-.125-.48.617.617,0,0,0-.309-.262,2.825,2.825,0,0,0-.777-.066Zm5.617,2.652V-3.07H12.73V-.789a2.5,2.5,0,0,1-.875.625A2.689,2.689,0,0,1,10.719.1,2.217,2.217,0,0,1,9.492-.244,2.316,2.316,0,0,1,8.65-1.268a3.838,3.838,0,0,1-.311-1.6,3.791,3.791,0,0,1,.314-1.617,2.364,2.364,0,0,1,.816-1,2.127,2.127,0,0,1,1.2-.332,2.015,2.015,0,0,1,1.346.428,2.035,2.035,0,0,1,.65,1.256l-.937.215a1.215,1.215,0,0,0-.387-.676,1.016,1.016,0,0,0-.672-.234,1.194,1.194,0,0,0-.98.471A2.282,2.282,0,0,0,9.316-2.93a2.586,2.586,0,0,0,.406,1.574,1.114,1.114,0,0,0,.941.465,1.434,1.434,0,0,0,.576-.131,1.8,1.8,0,0,0,.533-.357v-.727Z" transform="translate(2.887 20)" fill="#5a5a5a"/>
    </g>
    <g id="Group_7597" data-name="Group 7597" transform="translate(345 396.201)">
      <g id="pdf-2-4" data-name="pdf-2" opacity="0.504">
        <g id="Group_7589-4" data-name="Group 7589">
          <g id="Group_7588-4" data-name="Group 7588">
            <path id="Path_15430-4" data-name="Path 15430" d="M60.49,0H48.69a2.145,2.145,0,0,0-2.145,2.145V21.453A2.145,2.145,0,0,0,48.69,23.6H63.708a2.145,2.145,0,0,0,2.145-2.145V5.9ZM64.78,21.453a1.073,1.073,0,0,1-1.073,1.073H48.69a1.073,1.073,0,0,1-1.073-1.073V2.145A1.073,1.073,0,0,1,48.69,1.073h9.654V6.436a1.073,1.073,0,0,0,1.073,1.073H64.78V21.453ZM59.417,6.436V1.073h.536L64.78,6.436H59.417Z" transform="translate(-46.545)" fill="#5a5a5a"/>
          </g>
        </g>
      </g>
      <path id="Path_15434" data-name="Path 15434" d="M.473-5.727H2.2a2.576,2.576,0,0,1,.99.146,1.659,1.659,0,0,1,.615.494,2.54,2.54,0,0,1,.441.9A5.05,5.05,0,0,1,4.41-2.8a4.43,4.43,0,0,1-.168,1.291,2.589,2.589,0,0,1-.426.869,1.65,1.65,0,0,1-.623.482A2.331,2.331,0,0,1,2.254,0H.473Zm.945.969V-.965h.711a1.85,1.85,0,0,0,.662-.086.8.8,0,0,0,.336-.26,1.489,1.489,0,0,0,.217-.549,4.582,4.582,0,0,0,.086-.988,4.51,4.51,0,0,0-.088-1,1.33,1.33,0,0,0-.273-.578.87.87,0,0,0-.451-.277,3.746,3.746,0,0,0-.77-.051Zm3.605,1.93a4.067,4.067,0,0,1,.3-1.686A2.281,2.281,0,0,1,6.117-5.5,2.091,2.091,0,0,1,7.3-5.824a2.031,2.031,0,0,1,1.643.77,3.358,3.358,0,0,1,.635,2.191A3.414,3.414,0,0,1,8.906-.617,1.967,1.967,0,0,1,7.3.1,1.985,1.985,0,0,1,5.7-.609,3.369,3.369,0,0,1,5.023-2.828ZM6-2.867a2.436,2.436,0,0,0,.375,1.482,1.128,1.128,0,0,0,.93.494,1.114,1.114,0,0,0,.926-.49A2.512,2.512,0,0,0,8.6-2.887,2.458,2.458,0,0,0,8.24-4.359,1.116,1.116,0,0,0,7.3-4.836a1.128,1.128,0,0,0-.941.48A2.47,2.47,0,0,0,6-2.867Zm7.324.762.918.355a2.574,2.574,0,0,1-.715,1.4A1.788,1.788,0,0,1,12.3.1a1.822,1.822,0,0,1-1.488-.707,3.417,3.417,0,0,1-.656-2.2,3.587,3.587,0,0,1,.66-2.293,1.873,1.873,0,0,1,1.543-.719,1.779,1.779,0,0,1,1.32.539,2.339,2.339,0,0,1,.555,1.137l-.937.273a1.268,1.268,0,0,0-.357-.713.9.9,0,0,0-.631-.248,1,1,0,0,0-.848.453,2.612,2.612,0,0,0-.328,1.5,2.776,2.776,0,0,0,.32,1.539.975.975,0,0,0,.832.457.861.861,0,0,0,.648-.291A1.845,1.845,0,0,0,13.324-2.105Z" transform="translate(2.527 20)" fill="#5a5a5a"/>
    </g>
  </g>
</svg>
   </div>


      {currentFile &&
      <> 
      <div className="prog_con">
        <div className="prog_child" style={{width:`${progress}%`}}></div>
      </div>
      <div className="image_sec">
        <div className="imgsc">
          {console.log(imagePath)}
          {imagePath.map((im)=>{
          return(
           <>{(im.includes("png")||im.includes("jpg"))? <img alt="" className="image_elem" src={BACKEND_FIles_URL+im}/>:
           <img width="40" src={fileIcon}/>
           }</>
          )
        })}</div>
        <div className="add_paren">
          <div className="add_bu" style={{cursor:"pointer"}}>
          <label id="labek" tabIndex={"1"} role="button" for="browse"></label>
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="18.2" viewBox="0 0 13 18.2">
  <g id="_x32_9_Upload_cv" transform="translate(0)">
    <g id="Group_7587" data-name="Group 7587" transform="translate(0 0)">
      <path id="Path_15413" data-name="Path 15413" d="M94.2,215.255a.116.116,0,0,1-.116-.116v-.306a.116.116,0,0,1,.232,0v.307A.116.116,0,0,1,94.2,215.255Z" transform="translate(-93.344 -207.085)" fill="#5a5a5a"/>
      <path id="Path_15414" data-name="Path 15414" d="M94.2,240.874a.116.116,0,0,1-.116-.116v-.911a.116.116,0,1,1,.232,0v.911A.116.116,0,0,1,94.2,240.874Z" transform="translate(-93.344 -231.21)" fill="#5a5a5a"/>
      <path id="Path_15415" data-name="Path 15415" d="M74.544,28.477l1.205-1.294a.116.116,0,1,0-.17-.158l-1.205,1.294a.116.116,0,1,0,.17.158Z" transform="translate(-74.305 -26.033)" fill="#5a5a5a"/>
      <path id="Path_15416" data-name="Path 15416" d="M120.223,8.123l.22-.236a.116.116,0,1,0-.169-.158l-.22.236a.116.116,0,0,0,.169.158Z" transform="translate(-118.359 -7.424)" fill="#5a5a5a"/>
      <path id="Path_15417" data-name="Path 15417" d="M387.622,275.289a.116.116,0,0,0,.116-.116v-.324a.116.116,0,0,0-.232,0v.324A.116.116,0,0,0,387.622,275.289Z" transform="translate(-376.329 -264.967)" fill="#5a5a5a"/>
      <path id="Path_15418" data-name="Path 15418" d="M387.622,303.165a.116.116,0,0,0,.116-.116v-1.768a.116.116,0,0,0-.232,0v1.768A.116.116,0,0,0,387.622,303.165Z" transform="translate(-376.329 -290.459)" fill="#5a5a5a"/>
      <path id="Path_15419" data-name="Path 15419" d="M117.6,113.867h2.418a.116.116,0,1,0,0-.232H117.6a.116.116,0,1,0,0,.232Z" transform="translate(-115.909 -109.599)" fill="#5a5a5a"/>
      <path id="Path_15420" data-name="Path 15420" d="M117.6,152.351h3.645a.116.116,0,1,0,0-.232H117.6a.116.116,0,1,0,0,.232Z" transform="translate(-115.909 -146.714)" fill="#5a5a5a"/>
      <path id="Path_15421" data-name="Path 15421" d="M117.6,190.835h7.65a.116.116,0,1,0,0-.232H117.6a.116.116,0,1,0,0,.232Z" transform="translate(-115.909 -183.829)" fill="#5a5a5a"/>
      <path id="Path_15422" data-name="Path 15422" d="M117.6,229.317h7.65a.116.116,0,1,0,0-.232H117.6a.116.116,0,1,0,0,.232Z" transform="translate(-115.909 -220.942)" fill="#5a5a5a"/>
      <path id="Path_15423" data-name="Path 15423" d="M117.6,267.8h7.65a.116.116,0,1,0,0-.232H117.6a.116.116,0,1,0,0,.232Z" transform="translate(-115.909 -258.058)" fill="#5a5a5a"/>
      <path id="Path_15424" data-name="Path 15424" d="M117.6,306.285h7.65a.116.116,0,1,0,0-.232H117.6a.116.116,0,1,0,0,.232Z" transform="translate(-115.909 -295.173)" fill="#5a5a5a"/>
      <path id="Path_15425" data-name="Path 15425" d="M117.6,344.766h7.65a.116.116,0,0,0,0-.232H117.6a.116.116,0,0,0,0,.232Z" transform="translate(-115.909 -332.285)" fill="#5a5a5a"/>
      <path id="Path_15426" data-name="Path 15426" d="M83.915,13.129V1.255A1.11,1.11,0,0,0,82.8.145h-6.3a.125.125,0,0,0-.084.037L73.278,3.559a.129.129,0,0,0-.031.079V14.671a1.111,1.111,0,0,0,1.11,1.11h6.661a2.615,2.615,0,1,0,2.9-2.652Zm-8.978.634a.116.116,0,0,0,0,.232h6.739a2.6,2.6,0,0,0-.653,1.555H74.357a.879.879,0,0,1-.878-.878V3.754h2.037a1.11,1.11,0,0,0,1.11-1.11V.377H82.8a.879.879,0,0,1,.878.878v11.86h-.052a2.6,2.6,0,0,0-1.722.649Zm9.536,1.38a.116.116,0,0,1-.164,0l-.561-.561v2.575a.116.116,0,0,1-.232,0V14.582l-.561.561a.116.116,0,0,1-.164-.164l.76-.759a.116.116,0,0,1,.164,0l.759.759a.116.116,0,0,1,0,.164Z" transform="translate(-73.247 -0.145)" fill="#5a5a5a"/>
      <path id="Path_15427" data-name="Path 15427" d="M216.232,33.557a2.224,2.224,0,1,0,2.224,2.224A2.227,2.227,0,0,0,216.232,33.557Zm.986,2.708a.467.467,0,0,0-.395.135l-.592.592-.593-.593a.478.478,0,0,0-.4-.132,1.318,1.318,0,0,0-.793.391,1.993,1.993,0,1,1,3.578,0A1.317,1.317,0,0,0,217.218,36.265Z" transform="translate(-209.001 -32.369)" fill="#5a5a5a"/>
      <path id="Path_15428" data-name="Path 15428" d="M253.167,50.364c0,.486-.384,1.237-.895,1.237s-.9-.75-.9-1.237a.9.9,0,0,1,1.79,0Z" transform="translate(-245.041 -47.715)" fill="#5a5a5a"/>
    </g>
  </g>
</svg>
<svg style={{marginTop:"6px"}} xmlns="http://www.w3.org/2000/svg" width="15.396" height="15.396" viewBox="0 0 15.396 15.396">
  <path id="Path_18878" data-name="Path 18878" d="M13.6,5.9H9.494V1.8A1.8,1.8,0,1,0,5.9,1.8V5.9H1.8a1.8,1.8,0,1,0,0,3.592H5.9V13.6a1.8,1.8,0,1,0,3.592,0V9.494H13.6a1.8,1.8,0,1,0,0-3.592Zm0,3.079H9.238a.257.257,0,0,0-.257.257V13.6a1.283,1.283,0,1,1-2.566,0V9.238a.257.257,0,0,0-.257-.257H1.8a1.283,1.283,0,1,1,0-2.566H6.158a.257.257,0,0,0,.257-.257V1.8a1.283,1.283,0,1,1,2.566,0V6.158a.257.257,0,0,0,.257.257H13.6a1.283,1.283,0,1,1,0,2.566Z" fill="#5a5a5a"/>
</svg>
          </div>
        </div>
      </div>
      </>}
      </div>
     {currentFile &&
      <div className={`done_bu ${loading !== false && "dis"}`}
      onClick={(e) => {
if(loading === false){
addAndClose()}
}} 

      >
        {x[tr].Dones}
      </div>}
        {/* <div>
          {currentFile && (
            <div className="progress">
              <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
          )}
          <label className="btn btn-default" >
            <input type="file" onChange={selectFile} style={{color:'#e9ecef'}}/>
          </label>
     
          <div>
           
          </div>
        </div> */}
      </ModalBody>
      {/* <ModalFooter>
        <button
          className="btn btn-success"
          disabled={upd}
          onClick={upload}
        >
          Upload
        </button>

        <button closeButton
            className="btn btn-success"
            disabled={!finishuploadSuccessfully}
            onClick={(event) => addAndClose(fileInfos)}
        >
          add
        </button>

      </ModalFooter> */}
    </Modal>
  );
}