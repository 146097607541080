import { all, fork,put,takeEvery } from "@redux-saga/core/effects";
import { BACKEND_URL, COUNTRIES_URL, SUBMIT_URL } from "../../constants/endpointConfig";
import AxiosInstance from '../../helpers/axiosInstance';
import { GETDataI_RED, GETData_RED } from "./action";
import { GET_DATAI_ADV, GET_DATA_ADV } from "./constants";
// eslint-disable-next-line
import {NotificationManager} from 'react-notifications';
function* getData(action){
    try{
        yield put({type:'loading'})
        let response=yield AxiosInstance.post(BACKEND_URL+'candidates/create',JSON.stringify({name:action.data.name,email:action.data.email,candidate_files:action.data.cvlink.map((cv) => {return {file_path : cv}}),phone_number :action.data.phone,job_id:action.data.spec}));
        yield put(GETData_RED(response.data));
        yield put({type:'load'});
        // NotificationManager.success(' ', '!تم ارسال معلوماتك بنجاح');
    }catch(e){
            let title='فشل ارسال المعلومات';
            let d='يرجى التأكد من صحة المعلومات المدخلة أو المحاولة لاحقاً';
        yield put({type:'load'});
        //no-unused-vars
        NotificationManager.error(d,title);
        console.log(e);
    }
}
function* GetJobs(action){
    let response=  yield  AxiosInstance.get("https://ramaaz.com/ramaaz_official_site_backend/public/jobs")
      yield put({type:"GET-JOBS",payload:response.data.data})
}
function* watchGet(){
    yield takeEvery("GET-JOBS-SAGA",GetJobs)
}
function* watchGetData(){
    yield takeEvery(GET_DATA_ADV,getData)
}
function* getDataI(action){
    try{
        // yield put({type:'loading'})
        // let response=yield AxiosInstance.get("http://stagingadmin.ramaaz.online/wallet-users-backend/public/api/v1/job_titles");
        // console.log(response.data.data);
        // yield put(GETDataI_RED(response.data.data));
        // yield put({type:'load'})
    }catch(e){
        yield put({type:'load'})
        console.log(e);
    }
}
function* watchGetDataI(){
    yield takeEvery(GET_DATAI_ADV,getDataI)
}
function* getCountries(action){
    try{
        // yield put({type:'loading'})
        // let response=yield AxiosInstance.get(COUNTRIES_URL);
        // console.log(response.data.data);
        // yield put({type:"GET_COUNTRIES_RED",payload:response.data.data});
        // yield put({type:'load'})
    }catch(e){
        yield put({type:'load'})
        console.log(e);
    }
}
function* watchGetCountries(){
    yield takeEvery("GET_COUNTRIES_SAGA",getCountries)
}
function* submit(action){
    try{
        yield put({type:'loading'})
        let response=yield AxiosInstance.post(SUBMIT_URL,JSON.stringify(action.payload));
        console.log(response.data.data);
        yield put({type:"SUBMIT_RED",data:response.data.data});
        yield put({type:'load'})
    }catch(e){
        yield put({type:'load'})
        console.log(e);
    }
}
function* watchSubmit(){
    yield takeEvery("SUBMIT_SAGA",submit)
}
export function* ADVSaga(){
    yield all([fork(watchGetData),fork(watchGetDataI),fork(watchGet),fork(watchGetCountries),fork(watchSubmit)])
}