import React from 'react'
import HomeLogo from './HomeLogo'
import AddressInfo from './AddressInfo'
import "./Home.css"
function Home() {
  return (
    <div className="app-container">
      <div className='logo-container'>
      <HomeLogo/>
      </div>
      <AddressInfo/>
    </div>
  )
}

export default Home