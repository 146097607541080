import React from 'react'
import { useSelector } from 'react-redux'
import { x } from '../../assets/trans'
import trx from "./True.svg"
function ThanksPage() {
  const tr = useSelector((state) => state.trans.translate)

  return (
    <div className='thx-page'>
        <div className='thx-avatar'>
        <img src={trx}/>
        </div>
        <div className={`thx-text ${tr==="ar"&&"arab"}`}>
            <div className='thx-row'>{x[tr].request}</div>
            <div className='thx-row'>{x[tr].asap}</div>
            <div className='thx-row'>{x[tr].no}<span id="eng"> RT 100{JSON.parse(localStorage.getItem("user-id")).id}</span></div>
        </div>
    </div>
  )
}

export default ThanksPage