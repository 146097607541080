import {
    GET_DATAI_RED,
    GET_DATA_RED
} from "./constants";


export  function ADVReducer(state = {
    data: [],
    data_employee:{region_id:null,name:"",phone_number:"",email:"",job_title_id:null,cv_path:"",candidate_files:[{file_path:""},{file_path:""}]},
    loading:false,
    done:false,
    countries:[],
    ref:null,
    jobs:[]
    
}, action) {
    switch (action.type) {
        case "GET-JOBS":{
            return{
                ...state,
                jobs:action.payload
            }
        }
        case "SUBMIT_RED":{
            localStorage.setItem("user-id",JSON.stringify(action.data))
            return({
                ...state,
                ref:action.data
            })
        }
        case "GET_COUNTRIES_RED":{
            return({
                ...state,
                countries:action.payload
            })
        }
        case "UPD_EMPL":{
            return({
                ...state,
                data_employee:action.payload
            })
        }
        case "DS":{
            return{
                ...state,
                done:false
            }
        }
        case 'loading':{
            return({
                ...state,
                loading:true
            })
        }
        case 'load':{
            return({
                ...state,
                loading:false
            })}
            case GET_DATAI_RED: {
                return({
                    ...state,
                    data:action.data
                })
            }
            case GET_DATA_RED:{
                return{
                    ...state,
                    done:true
                }
            }
        default:
    }
    return state;
}