export const x ={
  "ar": {
"address":"ساريير - وادي اسطنبول - اسطنبول",
"cont1":"شركة للتجارة الالكترونية سوف نبدأ تقديم خدماتنا قريباً خلال شهر نوفمبر ٢٠٢٢ في مجال بيع وشراء المنتجات عبر الانترنت في دول الخليج العربي وبلاد الشام واوروبا وذلك من خلال تطبيقات الهاتف المحمول والويب.",
    "cont2":"لقد بدأنا بناء فريق عمل مميز ونحن ملزمين بأن يكون الفريق من النخبة لذلك لن نقوم بتحديد الوظائف المطلوبة بل سوف نترك لكم الخيار بتحديد مجال الابداع لديكم وذلك عبر ارسال سير ذاتية تشرح خبراتكم.",
    "emp":"  ١٠ / ١٠٠ موظف",
    "hr":"ساعات",
    "pd":"   ٨٠٠ / ١٠٠٠ دولار أمريكي",
    "pl":"الرجاء ملئ النموذج ادناه وبعدها سوف نقوم بدراسة طلباتكم والاتصال معكم.",
      "phone":"الهاتف",
    "email":"البريد الالكتروني",
    "spec":"المجال",
      "cv":"تحميل السيرة الذاتية مع صورة شخصية",
    "ch":"للتحقق",
    "snd":"ارسال",
      "soon":"قريبا...",
    "sn":"سيكون متاحا بعد الانتهاء من الإجراءات القانونية",
    "rec":"هذا السؤال لاختبار ما اذا كنت زائراً بشرياً أم لا ولمنع إرسال الرسائل غير المرغوب فيها تلقائياً",
      "done":"تم تحميل الملف",
      "name":"الاسم",
      "oppor":"فرصة",
      "joinUs":"انضم الى فريقنا",
      "job_word":"عـمل",
      "uploadc":"تحميل سيرة ذاتية",
      "Dones":"تم",
      "req":"تم ارسال الطلب بنجاح",
      "rev":"سنقوم بمراجعة الطلب والتواصل معك قريبا",
      "priv_desc":"الرجاء ملئ النموذج بدقة مع المعلومات الصحيحة.",
      "study":"سندرس طلبك باسرع مايمكن ثم سنتواصل معك.",
      "complete":"الطلبات الكاملة والواضحة لها الأولوية القصوى.",
      "can":" أنت تستطيع",
      "join":" الانضمام لنا",
      "simple":" بخطوات",
      "contact":"معلومات الاتصال",
      "steps":" بسيطة",
      "terms":"الشروط والقواعد",
      "agree":"أوافق",
      "reject":"أرفض",
      "country":"بلد / مكتب",
      "full_name":"الاسم",
      "work":"المجال",
      "docs":"الوثائق المطلوبة",
      "up":"الرجاء رفع الملفات المطلوبة بالاضافة للوثائق الاخرى مثل الشهادات الاكاديمية,الدورات الخ",
      "apply":"تم",
      "request":"تم انجاز طلبك بنجاح",
      "asap":"سنقوم بدراسة طلبك باسرع مايمكن",
      "no":"رقم الطلب :"






  },
  "en": {
    "address":" Sariyer - Vadistanbul - Istanbul",
    "cont1":"An e-commerce company, we will start providing our services soon during the month of November 2022 in the field of buying and selling products online in the Arab Gulf countries, the Levant and Europe, through mobile phone applications and the web.",
    "cont2":"We have started building a distinguished work team and we are obligated that the team be from the elite, so we will not specify the required jobs, but we will leave you the option to determine your field of creativity by sending CVs explaining your experiences.",
    "emp":"10 / 100 Officer",
    "hr":"hours",
      "pd":" 800 / 1000 US Dollar",
      "pl":"Please fill out the form below and then we will study your requests and contact you.",
    "phone":"phone",
    "priv_desc":"Please Fill Form Below Accurately And With Correct Information.",
    "study":"We Will Study Your Request ASAP, Then We Will Contact You.",

      "email":"e-mail",
    "spec":"Specialization",
      "cv":"Upload CV with photo",
      "ch":"for checking",
    "snd":"send",
      "soon":"soon ...",
    "sn":"It will be available after the completion of the legal procedures",
      "rec":"This question is to test whether you are a human visitor or not and to prevent spam from being automatically sent",
      "done":"file uploaded",
      "name":"Name",
      "oppor":"WE ARE",
      "joinUs":"JOIN OUR TEAM",
      "job_word":"HIRING",
      "uploadc":"Upload CV",
      "Dones":"Done",
      "req":"Your Request Has Been Successfully",
      "rev":"We Will Review The Request And Contact You Shortly",
      "complete":" Complete And Clear Applications Are Taken With Priority.",
      "can":"You Can ",
      "join":"Join Us ",
      "simple":"In Simple ",
      "steps":"Steps",
      "terms":"Terms And Conditions",
      "agree":"Agree",
      "reject":"Reject",
      "country":"Country / Office",
      "full_name":"Full Name",
      "contact":"Contact Info",
      "work":"Work Field",
      "docs":"Required Documents",
      "up":"Please Upload The Required Files In Addition To Other Files Such As Academic Certificates,Training Courses, Courses, Etc.",
      "apply":"Apply",
      "request":"Your Request Has Been Successfully",
      "asap":"We Will Study Your Request ASAP",
      "no":"Application No :"





  },
  "tr": {
    "address":" Sarıyer - Vadistanbul - İstanbul",
    "cont1":"Bir e-ticaret şirketi olarak 2022 Kasım ayı içerisinde Arap Körfezi ülkeleri, Levant ve Avrupa'da online ürün alım satımı alanında cep telefonu uygulamaları ve web üzerinden hizmet vermeye başlayacağız.",
    "cont2":"Seçkin bir çalışma ekibi oluşturmaya başladık ve ekibin seçkinlerden olması zorunluluğumuz var, bu yüzden gerekli işleri belirtmeyeceğiz ancak deneyimlerinizi anlatan CV'ler göndererek size yaratıcılık alanınızı belirleme seçeneği bırakacağız.",
    "emp":"10 / 100 Personel",
    "hr":"Saat",
    "pd":" 800 / 1000 Amerikan Doları",
      "pl":"Lütfen aşağıdaki formu doldurun, ardından isteklerinizi inceleyeceğiz ve sizinle iletişime geçeceğiz.",
    "phone":"telefon",
      "email":"e-posta",
    "spec":"iş Alanı",
      "cv":"Fotoğraflı CV Yükle",
      "ch":"kontrol etmek",
      "snd":"Gönder",
      "priv_desc":"Please Fill Form Below Accurately And With Correct Information.",
      "soon":"yakında...",
    "sn":"Yasal işlemlerin tamamlanmasının ardından hizmete açılacaktır.",
      "rec":"Bu soru, insan ziyaretçi olup olmadığınızı test etmek ve spam'in otomatik olarak gönderilmesini önlemek içindir.",
      "done":"Dosya yüklendi",
      "name":"Ad Soyad",
      "oppor":"İŞ",
      "joinUs":"BIZE KATiLiN",
      "job_word":"ILANi",
      "uploadc":"CV Yükle",
      "Dones":"Tamamlandı",
      "req":"Talebiniz Başarıyla Gerçekleştirildi",
      "rev":"Talebi İnceleyip En Kısa Sürede Sizinle İletişime Geçeceğiz",
      "study":"We Will Study Your Request ASAP, Then We Will Contact You.",
      "complete":" Complete And Clear Applications Are Taken With Priority.",
      "can":"You Can ",
      "join":"Join Us ",
      "simple":"In Simple ",
      "steps":"Steps",
      "terms":"Terms And Conditions",
      "agree":"Agree",
      "reject":"Reject",
      "country":"Country / Office",
      "full_name":"Full Name",
      "contact":"Contact Info",
      "work":"Work Field",
      "docs":"Required Documents",
      "up":"Please Upload The Required Files In Addition To Other Files Such As Academic Certificates,Training Courses, Courses, Etc.",
      "apply":"Apply",
      "request":"Your Request Has Been Successfully",
      "asap":"We Will Study Your Request ASAP",
      "no":"Application No :"











  }
}