//export const BACKEND_URL = "http://127.0.0.1:8000/";
//export const BACKEND_FIles_URL = "http://127.0.0.1:8000/";
export const BACKEND_URL = "https://ramaaz.com/ramaaz_official_site_backend/public/";
export const SUBMIT_URL="http://stagingadmin.ramaaz.online/wallet-users-backend/public/api/v1/candidates/create";
export const FLAG_URL="https://s3.eu-central-1.amazonaws.com/staging.ramaaz.com"
///////////////////////////////////////////////////////////////////////////////
export const COUNTRIES_URL="http://stagingadmin.ramaaz.online/ewallet_categories_content_service/public/api/v1/regions/get_countries"
export const BACKEND_FIles_URL ="https://ramaaz.com/ramaaz_official_site_backend/public";
//export const BACKEND_URL = "http://testmygraildo.co.uk/testmygraildo.co.uk/backend/backend_graildo/public/";
//export const BACKEND_FIles_URL = "http://testmygraildo.co.uk/testmygraildo.co.uk/backend/backend_graildo/public/";
export const CHAT_URL="https://ramaaz.dev/chating/public";
// export const CHAT_URL="http://shubrumah.net/chating/chating/public/";
// export const BACKEND_URL = "http://shubrumah.net/shubrumah.net/develop/public/";
export const GET_CHANNELS="channels/my_channels_with_pagination";
export const ADMIN_LOGIN_URL = "/admin/login";
export const SERVER_URL="http://ramaaz.dev"
export const GENERAL_LOGIN_URL = "general/login";
export const SINGLE_CATEGORY="categories/show/";
export const ACTIVE_CATEGORY="categories/show/"
export const SPECIAL_PRODUCTS_URL="products/products_of_categories";
export const All_SYSTEM_STATUS_URL = "business_opportunity/system_status";
export const TOP_COLLECTION_URL="products/products_of_intersect_categories";
export const MAIN_SLIDE="categories/show/38";
export const menWomen="categories/show/39";
export const FORGOTE_PASSWORD_URL = "forgetpassword_order";
export const RESET_PASSWORD_URL = "forgetpassword";
export const ACTIVATE_URL = "activate_user/";
export const DEACTIVATE_URL = "deactivate_user/";
export const PRODUCTS_URL='products/index'
export const CATEGORIES_URL="categories/show/8";
export const CART_URL="carts/my_active_cart";
export const WISHLIST_URL='WantedPhysicalProductButNotAvailables';
export const SINGLE_PRODUCT="products/show";
export const ADD_CART="physical_product_cart/create";
export const HOME="android/home";
export const ATTR='products/create';
export const SEARCH="products/search/";
export const CONTENTS="contents/content-by-category/";
export const ORDERS="orders/my-orders";
export const SINGLE_ORDER="orders/show";
