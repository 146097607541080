import React,{useState,useEffect} from 'react'
import info from "./info.svg"
import Input from './Input'
import point from "./point.svg"
import marker from "./marker.svg"
import user from "./user.svg"
import flag from "./flag.svg"
import flag1 from "./flag1.svg"
import ram from "./rammaz.svg"
import close from "./close.svg"
import menu from "./menu.svg"
import call from "./call.svg"
import mail from "./mail.svg"
import work from "./work.svg"
import link from "./link.svg"
import id from "./id.svg"
import {x} from "../../assets/trans"
import cv from "./cv.svg"
import pdf from "./pdf.svg"
import { useDispatch, useSelector } from 'react-redux'
import UploadPhoto from './upload/UploadPhoto'
import UploadPdf from './upload/UploadPdf'
import UploadCv from './upload/UploadCv'
import { FLAG_URL } from '../../constants/endpointConfig'
import { validateEmail } from '../../helpers/utility'
function PrivacyForm(props) {
    const ValidFiled=(id)=>{
       let elem= document.querySelector(`#${id}`)
       if(elem){
        elem.classList.add("animate")
        elem.classList.add("red-bord")
       setTimeout(() => {
        elem.classList.remove("animate")
        elem.classList.remove("red-bord")
       }, 1500);}
    }
    const submit=()=>{
        if(VaildData()){
            dispatch({type:"SUBMIT_SAGA",payload:data_employee})
        }
        else{
            if(data_employee.name.length===0){ValidFiled("s2")}
            if(data_employee.phone_number.length<5){ValidFiled("s3")}
            if(!validateEmail(data_employee.email)){ValidFiled("s4")}
            if((!data_employee.region_id)){ValidFiled("s1")}
            if((!data_employee.job_title_id)){ValidFiled("s5")}
            if((data_employee.cv_path.length===0)){ValidFiled("s6")}
            if((data_employee.candidate_files[0].file_path.length===0)){ValidFiled("s7")}
            if((data_employee.candidate_files[1].file_path.length===0)){ValidFiled("s8")}
        }
    }
    const VaildData=()=>{
        if(data_employee.name.length>0&&
            validateEmail(data_employee.email) &&
            data_employee.phone_number.length>0  &&
            data_employee.region_id &&
            data_employee.job_title_id &&
            data_employee.candidate_files[0].file_path.length>0&&
            data_employee.candidate_files[1].file_path.length>0 &&
            data_employee.cv_path.length>0
            ){
                return true
            }
            else {
                return false
            }
    }
    const dispatch=useDispatch()
    const [menuOpen,setOpen]=useState(false)
    const tr = useSelector((state) => state.trans.translate)
    const sd = useSelector((state) => state.trans.sd)
    const data_employee=useSelector((state)=>state.ADV.data_employee)
    const loading=useSelector((state)=>state.ADV.loading)
    const [menuOpen1,setOpen1]=useState(false)
    const conts=useSelector((state)=>state.ADV.countries)
    const ref=useSelector((state)=>state.ADV.ref)
    const jobs=useSelector((state)=>state.ADV.data)
    const update_data=(s)=>{
        dispatch({type:"UPD_EMPL",payload:s})
    }
    useEffect(()=>{
        if(ref&&ref.id){
            props.setProgrees()
        }
    },[ref])
  return (
    <div className={`privacy-container ${tr==="ar"&&"arab"}`}>
      <div className='privacy-squ'>
            <div className='privacy-row'>
                <img src={info}/>
                <div className='privacy-text'>
                {x[tr].can} <span>{x[tr].join}</span>  {x[tr].simple}<span>{x[tr].steps}</span>
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"10px",minWidth:"312px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"12px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px",minWidth:"312px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"12px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].study}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px",minWidth:"312px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"12px",marginLeft:"5px",letterSpacing:"0em"}}>
               {x[tr].complete}
                </div>
            </div>
        </div>
    <div className='form-squ' >
        <div className='input-holder poin' style={{cursor:"pointer"}} onClick={(e)=>{e.preventDefault(); setOpen(!menuOpen)}}>
            {menuOpen&&
            <div className='menu-items'>
                {conts.map((a)=>{
                    return(
                        <div className='menu-item' onClick={()=>{setOpen(false); update_data({...data_employee,region_id:a.id})}}>
                            <div className='flag-icon'><svg width="15" height={"15"} data-src={FLAG_URL+a.flag_photo_path}></svg></div>
                            <div className='flag-text'>{(a.region_translations.filter((s)=>s.language_code===tr).length>0&&a.region_translations.filter((s)=>s.language_code===tr)[0].region_name)||a.region_translations[0].region_name}</div>
                        </div>
                    )
                })}
            </div>}
        <div className='close-icon'>
          <img src={menu} onClick={()=>{;}}/>
          </div>
            {data_employee.region_id&&<div className='flag'>
                {conts.filter((s)=>s.id===data_employee.region_id)[0].flag_photo_path&&<svg width="15" height={"15"} data-src={FLAG_URL+conts.filter((s)=>s.id===data_employee.region_id)[0].flag_photo_path}></svg>} <div className='flag-text'>{(conts.filter((s)=>s.id===data_employee.region_id)[0].region_translations.filter((s)=>s.language_code===tr).length>0&&conts.filter((s)=>s.id===data_employee.region_id)[0].region_translations.filter((s)=>s.language_code===tr)[0].region_name)||conts.filter((s)=>s.id===data_employee.region_id)[0].region_translations[0].region_name}</div></div>}
            <Input id="s1" pad={true} label={x[tr].country} icon={marker} value="" onChange={(e)=>{;}}/>
        </div>
        <div className='input-holder' style={{marginTop:"18px"}}>
         <div className='close-icon'>
          <img src={close} onClick={()=>{update_data({...data_employee,name:''});;}}/>
          </div>
            <Input id="s2"  pad={false} label={x[tr].full_name} icon={user} value={data_employee.name} onChange={(e)=>{update_data({...data_employee,name:e});}}/>
        </div>
        <div className='input-holder' style={{marginTop:"18px"}}>
         <div className='close-icon'>
          <img src={close} onClick={()=>{update_data({...data_employee,phone_number:""});;}}/>
          </div>
            <Input id="s3" type="number"  pad={false} label={x[tr].contact} icon={call} value={data_employee.phone_number} onChange={(e)=>{update_data({...data_employee,phone_number:e});}}/>
           
        </div>
        <div className='input-holder' style={{marginTop:"8px"}}>
        <div className='close-icon'>
          <img src={close} onClick={()=>{update_data({...data_employee,email:""});;}}/>
          </div>
        <Input id="s4" type="email"  pad={false}  icon={mail} value={data_employee.email} onChange={(e)=>{update_data({...data_employee,email:e});}}/>
        </div>
        <div className='input-holder poin' style={{marginTop:"18px",cursor:"pointer"}} onClick={(e)=>{e.preventDefault(); setOpen1(!menuOpen1)}}>
        {menuOpen1&&
            <div className='menu-items'>
                {jobs.map((a)=>{
                    return(
                        <div className='menu-item' onClick={()=>{setOpen(false); update_data({...data_employee,job_title_id:a.id});}}>
                            
                            <div className='flag-text'>{(a.translations.filter((s)=>s.language_code===tr).length>0&&a.translations.filter((asd)=>asd.language_code===tr)[0].name)||a.translations[0].name}</div>
                        </div>
                    )
                })}
            </div>}
        <div className='close-icon'>
          <img src={menu} onClick={()=>{;}}/>
          </div>
          {data_employee.job_title_id&&<div className='flag'> <div className='flag-text'>{(jobs.filter((s)=>s.id===data_employee.job_title_id)[0].translations.filter((asd)=>asd.language_code===tr).length>0&&jobs.filter((s)=>s.id===data_employee.job_title_id)[0].translations.filter((asd)=>asd.language_code===tr)[0].name)||jobs.filter((s)=>s.id===data_employee.job_title_id)[0].translations[0].name}</div></div>}
            <Input id="s5"  pad={false} label={x[tr].work} icon={work} value="" onChange={(e)=>{;}}/>
           
        </div>
        <div className='input-holder' style={{marginTop:"18px"}}>
      <div className='input-label'>{x[tr].docs}</div>
      <div className='input-element-desc'>
        <img src={link}/>
        <div className='input-desc'>
        {x[tr].up}<span> png, pdf, jpg, doc, scan</span>
        </div>
        </div>   
      <div className='input-element-desc' style={{marginTop:"10px"}}>

        <UploadPhoto data={data_employee.candidate_files[0].file_path} change={(e)=>update_data({...data_employee,candidate_files:[{file_path:e},data_employee.candidate_files[1]]})} photo={id}/>
        <UploadPdf data={data_employee.candidate_files[1].file_path} change={(e)=>update_data({...data_employee,candidate_files:[data_employee.candidate_files[0],{file_path:e}]})} photo={cv}/>
        <UploadCv data={data_employee.cv_path} change={(e)=>update_data({...data_employee,cv_path:e})} photo={pdf}/>
        </div>
        <div className='logo-mob'>
        <img src={ram}/>
        </div>   
        <div className={'apply-button '+`${(!VaildData()||loading)&&"opac"}`} onClick={()=>submit()}>
        {x[tr].apply}
        </div> 
        </div>
    </div>
    </div>
  )
}

export default PrivacyForm