import React,{useState} from "react"
import { BACKEND_FIles_URL } from "../../../constants/endpointConfig"
import {uploadFile} from "../../uploadService"
const UploadPhoto=(props)=>{
    let photo=props.photo
const [progress,setProgrees]=useState(0)
const [loading,setLoading]=useState(false)
const upload= async (file)=>{
    let num=parseInt(Math.random()*1000);
    setLoading(true);
    let a=await uploadFile(`img-${num}`,"",file,(e)=>{
        let total=file.size;
        let loaded=e.loaded;
        setProgrees((100*loaded)/total)
    });
    props.change(a.data.data)
    setLoading(false);
    setProgrees(0)
}
const getImg=()=>{
    let Image = document.createElement("input");
    Image.onchange = (e) => {
        console.log(e)
        upload(e.target.files[0]);
    };
    Image.type = "file";
    Image.hidden = true;
    Image.accept = "image/*";
    Image.style = { position: "absolute", opacity: "0" };
    let i = document.body.appendChild(Image);
    i.click();
}
return(
    <div className='img-item'>
    {props.data.length===0?<svg id="s7" onClick={()=>getImg()} data-src={photo}></svg>:<img width={"60"} height="70" style={{borderRadius:"10px",objectFit:"cover"}} src={BACKEND_FIles_URL+props.data}/>}
   { loading&&<div className='progress-bars'>
       <div className='progress-in' style={{width:`${progress}%`}}>

        </div>
    </div>}
</div>
)
}
export default UploadPhoto