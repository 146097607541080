import React from 'react'
import Marker from '../Marker'
import SendIcon from '../SendIcon'

function AddressInfo() {
  return (
    <div className='address-info'>
        <Marker/>
        <div className='address-text'>
        Vadistanbul Bulvar . 2A/28 . Sarıyer . İstanbul . Turkiye
        </div>
        <div className='send-badge'>
            <SendIcon/> <span>Send To Us</span>
        </div>
    </div>
  )
}

export default AddressInfo