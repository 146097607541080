// @flow


/**
 * Checks if user is authenticated
 */

const isUserAuthenticated = () => {
    const user = getLoggedInUser();

    if (!user) {
        return false;
    }
    // const decoded = jwtDecode(user.token);
    //  const currentTime = Date.now() / 1000;
    //  if (decoded.exp < currentTime) {
    //      console.warn('access token expired');
    //     return false;
    // } else {
    return true;
    // }
};
//const dispatch = useDispatch();
/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    // const cookies = new Cookies();
 
    // let user = cookies.get('user');
   
    // if (!user) {
    //   //  user = localStorage.getItem('user');
    // }
    // //dispatch( userProfileSaga(user.user_na))
    //  //console.table(user);
    // return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;

const user={
    "id": 3,
    "username": "ali",
    "email": "vendor3@test.com",
    "email_verified_at": null,
    "verified": "0",
    "verification_token": null,
    "avatar": "/storage/users/avatars/avatar",
    "mobile_phone": null,
    "surname": null,
    "birthdate": null,
    "gender": null,
    "address": null,
    "default_lang_id": "1",
    "role_id": "3",
    "user_status_id": "1",
    "region_id": null,
    "postal_code": null,
    "is_active_by_admin": null,
    "facebook_account": null,
    "instagram_account": null,
    "telegram_account": null,
    "tiktok_account": null,
    "website_url": null,
    "expires_in": 2591999,
    "token_type": "Bearer",
    "role": "vendor",
    "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNTI5MWJmZDg5YjFiMDZiOWFjODUxMDZmNDlmYmZlNzZmYmJkMzJmYmM0ZDRlNThjZjNhODIzMmM1NzY4YWM2ZGUxZGY0M2ZmNzMyNzBiNDUiLCJpYXQiOjE2MzE0Njk4OTcuMjY4MDYsIm5iZiI6MTYzMTQ2OTg5Ny4yNjgwNjMsImV4cCI6MTYzNDA2MTg5Ny4wMTIxNCwic3ViIjoiMyIsInNjb3BlcyI6WyJ2ZW5kb3IiXX0.DHTwKtUaqs_wCkJBoUxs-H_9G29QHdmsCZULFbhNbzBzLSXIzlakhrY64WI_4pTOTzWqtSVgdTA-6BcjillALjomklV6V5VJotHEoYA0_pOTOv29r72uHDeFAIxRb4VKHQn0fCO3vX2kpPXWwtFVh77Kvd86awKqrqvotInGq2SOyja9_aOYwV9qyu8qrLxsst25GXRXv8V5BsaB8SYl7aTmCezFJJz22cIPCT1fzkF8IS4ZXUcqPMjl-bKXFpa6sVHOb-Im-VrjSBvXVxGWrL1hxIYtHfIanP40q74VcqwqaWWVYxfyHoCN8xGOcG4ghNh5Ssb0OycqLBVrcMYskmheT0Rax5ns7jAzv_U9z5-8omFI4ULyEE4sktPfLP9vu_8Y10O3Z__Beob9GngtiZgbYvhdDynmbDdB08DaToyAB07mBl568rTECym0CMw3UJ_eX7U4PiPyI9yQ3-WGH2Y-UmeIf-u6xvdQLhKrfcGg1o4fl5vUGhgqEypN3E4v39fq9z8u2Czd2AZJybI0JpI5R5-tM67OiI9EIWy8zSgT7F45Uv0wfuXUH-nmA2Kw1GmuBPE037YsYlQNOMAG3wyQot9z0sxO02vYUZ5yw2VcaITPMnrWQX2s1GsWEXppOBqBFAXdJHyl1zI3Ia4YAEUZtlJceCGtKXAWlV5l_6o"
};
return user;
};

export { isUserAuthenticated, getLoggedInUser };
