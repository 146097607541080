import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { x } from '../../assets/trans';
import PrivacyForm from './PrivacyForm';
import PrivacyWidget from './PrivacyWidget';
import ThanksPage from './ThanksPage';

function Form(props) {
    let navigate=useNavigate()
    const tr = useSelector((state) => state.trans.translate)
    useEffect(()=>{
        if(localStorage.getItem("user-id")&&JSON.parse(localStorage.getItem("user-id"))&&JSON.parse(localStorage.getItem("user-id")).id){
            setProgrees(3)
        }
    },[])
    const [progress,setProgrees]=useState(1)
    const ShowForm=()=>{
        switch (progress) {
            case 1:
                return(<PrivacyWidget setProgrees={()=>setProgrees(2)}/>)
            case 2:
                return(<><PrivacyForm setProgrees={()=>setProgrees(3)}/></>)
            case 3:
                return(
                <>
                <div className='done-button' onClick={()=>{navigate("/")}}>
                    {x[tr].Dones}
                </div>
                <ThanksPage/>
                </>)
            default:
                break;
        }
    }
  return (
    <div className='form-cont'>
{ShowForm()}
    </div>
  )
}

export default Form