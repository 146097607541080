import { combineReducers } from 'redux';
import { IntlReducer as Intl } from 'react-redux-multilingual' 
import {ADVReducer} from './advertisment/reducer';
import {Reducer} from "./translate/reducer"
// Import custom components

const rootReducer = combineReducers({
     ADV: ADVReducer,
    trans:Reducer
    ,Intl
});

export default rootReducer;
