
import {AxiosFileInstance } from "../helpers/axiosInstance";
import {getLoggedInUser} from '../helpers/authUtils';
export const uploadFile = (file_name, file_path,file, onUploadProgress) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file_name);
    formData.append("file_path", '/photos');
    return AxiosFileInstance.post("/managing/image-upload", formData, {
        headers: {
	     Authorization: 'Bearer ' + (getLoggedInUser() && getLoggedInUser().token),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
}

