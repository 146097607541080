import React,{useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux'; 
import ADVComponent from '../../components/Advertisment/index'
import {getDATAI_SAGA, getDATA_SAGA} from '../../reducers/advertisment/action';
import 'react-notifications/lib/notifications.css';
// import { withRouter } from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import ReactGA from 'react-ga';
import {trans} from "../../reducers/translate/actions";
const ADVContainer=({history})=>{
    const dispatch=useDispatch();
    let loading=useSelector((state)=>state.ADV.loading);
    let data=useSelector((state)=>state.ADV.jobs);
        const tr = useSelector((state) => state.trans.translate)
    const send=(data)=>{
        dispatch(getDATA_SAGA(data)) 
    }
    useEffect(()=>{
        dispatch({type:"GET-JOBS-SAGA"})

    },[])
    history?.listen((location, action) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });
    return(
        <>
                            <div className={`${tr !== "ar" && "rl" } ${tr ==="tr" && "ls5"} fix`}>
                        <div className={"menus"}>
                            <div id='basic-button'
                                 aria-controls='basic-menu'
                                 aria-haspopup='true'
                                 className={'lang_btn'}
                                 aria-expanded={true}
                                 color={`${tr === "en" ? '#FFF' : '#5A5A5A'}`}
                                 style={{
                                     backgroundColor: `${tr === "en" ? '#5A5A5A' : '#FFFFFF'}`,
                                     color: `${tr === "en" ? '#FFF' : '#5A5A5A'}`,
                                     borderRadius: '10px',
                                 }}
                                 onClick={(e) => {

                                     dispatch(trans('en'))
                                 }}>

                                EN
                            </div>
                            <div id='basic-button'
                                 aria-controls='basic-menu'
                                 aria-haspopup='true'
                                 className={'lang_btn'}
                                 aria-expanded={true}
                                 color={`${tr === "en" ? '#FFF' : '#5A5A5A'}`}
                                 style={{
                                     backgroundColor: `${tr === "tr" ? '#5A5A5A' : '#FFFFFF'}`,
                                     color: `${tr === "tr" ? '#FFF' : '#5A5A5A'}`,
                                     borderRadius: '10px',
                                 }}
                                 onClick={(e) => {
                                     dispatch(trans('tr'));
                                 }}>

                                TR
                            </div>
                            <div id='basic-button'
                                 aria-controls='basic-menu'
                                 aria-haspopup='true'
                                 className={'lang_btn'}
                                 aria-expanded={'true'}
                                 color={`${tr === "en" ? '#FFF' : '#5A5A5A'}`}
                                 style={{
                                     backgroundColor: `${tr === "ar" ? '#5A5A5A' : '#FFFFFF'}`,
                                     color: `${tr === "ar" ? '#FFF' : '#5A5A5A'}`,
                                     borderRadius: '10px',
                                 }}
                                 onClick={(e) => {
                                     dispatch(trans('ar'));

                                 }}>

                                ع
                            </div>

                        </div>
                    </div>
          <NotificationContainer/>
        <ADVComponent data={data} loading={loading} send={(d)=>send(d)}/>
        </>
    )
}
export default ADVContainer;