import React from 'react'

function SendIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
  <g id="Group_9992" data-name="Group 9992" transform="translate(-165 -862)">
    <path id="send-2" d="M9.3,2.906,4.916,4.361c-2.944.985-2.944,2.59,0,3.57l1.3.432.432,1.3c.98,2.944,2.59,2.944,3.57,0l1.46-4.375c.65-1.964-.417-3.036-2.382-2.382Zm.155,2.61L7.608,7.369a.363.363,0,0,1-.514,0,.366.366,0,0,1,0-.514L8.937,5a.364.364,0,0,1,.514.514Z" transform="translate(162.5 860.128)" fill="#1854d9"/>
    <rect id="Rectangle_5448" data-name="Rectangle 5448" width="10" height="10" transform="translate(165 862)" fill="none"/>
  </g>
</svg>

  )
}

export default SendIcon