export const GET_DATA_ADV="GET_DATA_ADV_SAGA";
export const GET_DATA_RED="GET_DATA_ADV_RED";
export const GET_DATAI_ADV="GET_DATAI_ADV";
export const GET_DATAI_RED="GET_DATAI_ADV_RED";
export const jobs=[
  {
    "id": 2,
    "is_available": "1",
    "job_translations": [
      {
        "id": 2,
        "job_id": "2",
        "translation_lang_id": "1",
        "name": "ازياء وموضة",
        "description": null,
        "notes": null
      },
      {
        "id": 15,
        "job_id": "2",
        "translation_lang_id": "2",
        "name": "fashion model",
        "description": null,
        "notes": null
      },
      {
        "id": 51,
        "job_id": "2",
        "translation_lang_id": "3",
        "name": "Moda ve stil",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 3,
    "is_available": "1",
    "job_translations": [
      {
        "id": 3,
        "job_id": "3",
        "translation_lang_id": "2",
        "name": "programming and developing",
        "description": null,
        "notes": null
      },
      {
        "id": 13,
        "job_id": "3",
        "translation_lang_id": "1",
        "name": "برمجة وتطوير",
        "description": null,
        "notes": null
      },
      {
        "id": 46,
        "job_id": "3",
        "translation_lang_id": "3",
        "name": "Yazilim",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 6,
    "is_available": "1",
    "job_translations": [
      {
        "id": 18,
        "job_id": "6",
        "translation_lang_id": "1",
        "name": "خدمة عملاء",
        "description": null,
        "notes": null
      },
      {
        "id": 19,
        "job_id": "6",
        "translation_lang_id": "2",
        "name": "customer service",
        "description": null,
        "notes": null
      },
      {
        "id": 44,
        "job_id": "6",
        "translation_lang_id": "3",
        "name": "Müşteri Hizmetleri",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 7,
    "is_available": "1",
    "job_translations": [
      {
        "id": 20,
        "job_id": "7",
        "translation_lang_id": "2",
        "name": "supply products",
        "description": null,
        "notes": null
      },
      {
        "id": 39,
        "job_id": "7",
        "translation_lang_id": "1",
        "name": "توريد المنتجات",
        "description": null,
        "notes": null
      },
      {
        "id": 45,
        "job_id": "7",
        "translation_lang_id": "3",
        "name": "Ürün Tedariği",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 8,
    "is_available": "1",
    "job_translations": [
      {
        "id": 21,
        "job_id": "8",
        "translation_lang_id": "2",
        "name": "E-Marketing",
        "description": null,
        "notes": null
      },
      {
        "id": 40,
        "job_id": "8",
        "translation_lang_id": "1",
        "name": "التسويق الإلكتروني",
        "description": null,
        "notes": null
      },
      {
        "id": 57,
        "job_id": "8",
        "translation_lang_id": "3",
        "name": "E-Pazarlama",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 9,
    "is_available": "1",
    "job_translations": [
      {
        "id": 50,
        "job_id": "9",
        "translation_lang_id": "3",
        "name": "Muhasebe ve Sekreterlik",
        "description": null,
        "notes": null
      },
      {
        "id": 58,
        "job_id": "9",
        "translation_lang_id": "2",
        "name": "Accounting and Secretarial",
        "description": null,
        "notes": null
      },
      {
        "id": 59,
        "job_id": "9",
        "translation_lang_id": "1",
        "name": "محاسبة وسكرتاريا",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 10,
    "is_available": "1",
    "job_translations": [
      {
        "id": 23,
        "job_id": "10",
        "translation_lang_id": "1",
        "name": "شحن ولوجيستك",
        "description": null,
        "notes": null
      },
      {
        "id": 32,
        "job_id": "10",
        "translation_lang_id": "2",
        "name": "Shipping and Logistics",
        "description": null,
        "notes": null
      },
      {
        "id": 55,
        "job_id": "10",
        "translation_lang_id": "3",
        "name": "Nakliye ve Lojistik",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 11,
    "is_available": "1",
    "job_translations": [
      {
        "id": 24,
        "job_id": "11",
        "translation_lang_id": "1",
        "name": "علاقات عامة",
        "description": null,
        "notes": null
      },
      {
        "id": 33,
        "job_id": "11",
        "translation_lang_id": "2",
        "name": "Public relations",
        "description": null,
        "notes": null
      },
      {
        "id": 47,
        "job_id": "11",
        "translation_lang_id": "3",
        "name": "Halkla İlişkiler",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 12,
    "is_available": "1",
    "job_translations": [
      {
        "id": 25,
        "job_id": "12",
        "translation_lang_id": "1",
        "name": "التصوير والتصميم الدعائي",
        "description": null,
        "notes": null
      },
      {
        "id": 34,
        "job_id": "12",
        "translation_lang_id": "2",
        "name": "Photography and advertising design",
        "description": null,
        "notes": null
      },
      {
        "id": 48,
        "job_id": "12",
        "translation_lang_id": "3",
        "name": "Fotoğraf ve reklam tasarımı",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 13,
    "is_available": "1",
    "job_translations": [
      {
        "id": 26,
        "job_id": "13",
        "translation_lang_id": "1",
        "name": "قانوني",
        "description": null,
        "notes": null
      },
      {
        "id": 35,
        "job_id": "13",
        "translation_lang_id": "2",
        "name": "Lawyer",
        "description": null,
        "notes": null
      },
      {
        "id": 49,
        "job_id": "13",
        "translation_lang_id": "3",
        "name": "Hukuk",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 14,
    "is_available": "1",
    "job_translations": [
      {
        "id": 27,
        "job_id": "14",
        "translation_lang_id": "2",
        "name": "content management",
        "description": null,
        "notes": null
      },
      {
        "id": 43,
        "job_id": "14",
        "translation_lang_id": "1",
        "name": "ادارة المحتوى",
        "description": null,
        "notes": null
      },
      {
        "id": 52,
        "job_id": "14",
        "translation_lang_id": "3",
        "name": "İçerik yönetimi",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 15,
    "is_available": "1",
    "job_translations": [
      {
        "id": 28,
        "job_id": "15",
        "translation_lang_id": "1",
        "name": "غير ذلك",
        "description": null,
        "notes": null
      },
      {
        "id": 36,
        "job_id": "15",
        "translation_lang_id": "2",
        "name": "other",
        "description": null,
        "notes": null
      },
      {
        "id": 54,
        "job_id": "15",
        "translation_lang_id": "3",
        "name": "diğer",
        "description": null,
        "notes": null
      }
    ]
  },
  {
    "id": 16,
    "is_available": "1",
    "job_translations": [
      {
        "id": 29,
        "job_id": "16",
        "translation_lang_id": "1",
        "name": "الفوركس والتداول",
        "description": null,
        "notes": null
      },
      {
        "id": 30,
        "job_id": "16",
        "translation_lang_id": "2",
        "name": "Forex",
        "description": null,
        "notes": null
      },
      {
        "id": 53,
        "job_id": "16",
        "translation_lang_id": "3",
        "name": "Forex",
        "description": null,
        "notes": null
      }
    ]
  }
]