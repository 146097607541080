import { GET_DATA_ADV, GET_DATA_RED,GET_DATAI_ADV,GET_DATAI_RED } from "./constants"

export const getDATA_SAGA=(data)=>{
    return{
        type:GET_DATA_ADV,

        data
    }
}
export const GETData_RED=(data)=>{
    return{
        type:GET_DATA_RED,
        data
    }
}
export const getDATAI_SAGA=(data)=>{
    return{
        type:GET_DATAI_ADV,
    }
}
export const GETDataI_RED=(data)=>{
    return{
        type:GET_DATAI_RED,
        data
    }
}