import axios from 'axios';
import { BACKEND_URL,BACKEND_FIles_URL } from '../constants/endpointConfig.js'
import { getLoggedInUser } from './authUtils';

const AxiosInstance = axios.create({
    baseURL: BACKEND_URL,
    timeout: 0,
    headers: {
        Authorization: 'Bearer ' + (getLoggedInUser() && getLoggedInUser().token),
        'Content-Type': 'application/json'

    },
})


export const AxiosFileInstance = axios.create({
    baseURL: BACKEND_FIles_URL,
    timeout: 0,
    headers: {
        Authorization: 'Bearer ' + (getLoggedInUser() && getLoggedInUser().token),
        'Content-Type': 'application/json'

    },
})

export const AxiosChatInstance = axios.create({
    baseURL: BACKEND_FIles_URL,
    timeout: 0,
    headers: {
        Authorization: 'Bearer ' + (getLoggedInUser() && getLoggedInUser().token),
        'Content-Type': 'application/json'

    },
})
AxiosInstance.interceptors.request.use(
    config => {
        config.headers.Authorization = 'Bearer ' + (getLoggedInUser() && getLoggedInUser().token);
        return config;
    }
);


export default AxiosInstance