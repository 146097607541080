import React from 'react'
import { useSelector } from 'react-redux'
import info from "./info.svg"
import {x} from "../../assets/trans"

import point from "./point.svg"
function PrivacyWidget(props) {
    const tr = useSelector((state) => state.trans.translate)

  return (
    <div className={`privacy-container ${tr==="ar"&&"arab"}`}>
        <div className='privacy-squ'>
            <div className='privacy-row'>
                <img src={info}/>
                <div className='privacy-text'>
                {x[tr].can} <span>{x[tr].join}</span>  {x[tr].simple}<span>{x[tr].steps}</span>
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"10px",minWidth:"312px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"12px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px",minWidth:"312px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"12px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].study}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px",minWidth:"312px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"12px",marginLeft:"5px",letterSpacing:"0em"}}>
               {x[tr].complete}
                </div>
            </div>
        </div>
        <div className='privacy-squ-main' style={{position:"relative"}}>
        <div className='privacy-row' style={{position:"absolute",top:"28px"}}>
                <img src={info}/>
                <div className='privacy-text'>
                {x[tr].terms}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"10px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
                        <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
             <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            <div className='privacy-row' style={{marginTop:"2px"}}>
                <img src={point}/>
                <div className='privacy-text' style={{fontSize:"10px",marginLeft:"5px",letterSpacing:"0em"}}>
                {x[tr].priv_desc}
                </div>
            </div>
            </div>
            <div className='privacy-button'>
                <div className='button-ag' onClick={()=>props.setProgrees(2)}>{x[tr].agree}</div>
                <div className='button-dec'>{x[tr].reject}</div>
            </div>
    </div>
  )
}

export default PrivacyWidget